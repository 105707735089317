import { l10nError } from "@elsa-zku/lib/helpers/l10nErrors";

export const clearInputError = (input: HTMLInputElement) => {
  const parent = input.parentElement;
  parent.classList.remove('input--error');
  parent.querySelector('.error').innerHTML = '';
  delete input.error;
};

export const setInputError = (input: HTMLInputElement, error: string) => {
  const parent = input.parentElement;
  parent.classList.add('input--error');
  parent.querySelector('.error').innerHTML = l10nError(error) || '';
  input.error = true;
};

export const validateInput = (el: HTMLInputElement, force?: boolean) => {
  if (force) el.touched = true;
  const { touched, validators, validatorDependency } = el;
  if (touched) {
    if (validators) {
      for (let i = 0; i < validators.length; i++) {
        const validation = validators[i](el);
        if (validation) return setInputError(el, validation);
      }
    }
    if (validatorDependency) validateInput(validatorDependency);
    clearInputError(el);
  }
};

export const codeMask = (el: HTMLInputElement, codeLength = 6) => {
  const clearInput = () => requestAnimationFrame(() => {
    el.value = el.value.replace(/[^+0-9]/g, '').slice(0, codeLength);
  });

  el.addEventListener('keydown', (e) => {
    // console.log(e)
    if (e.metaKey) return;
    const { key } = e;
    const isLetter = key.length === 1;
    const isValidKey = key.match(/^[0-9]$/);
    if (isLetter && !isValidKey) {
      e.preventDefault();
      e.stopPropagation();
    }
    clearInput();
  });
  el.addEventListener('paste', clearInput);
}

document.querySelectorAll('input').forEach(function (el) {
  el.touched = false;
  let changed = false;

  el.addEventListener('blur', function () {
    if (!changed) return;
    el.touched = true;
    el.value = el.value.trim();
    validateInput(el);
  });

  el.addEventListener('change', function () {
    validateInput(el);
  });

  el.addEventListener('input', function () {
    changed = true;
    validateInput(el);
  });
});
