import { localStorage } from "@elsa-zku/lib/helpers/localStorage";
import { USER_TYPE } from "@elsa-zku/lib/constants/userTypes";
import { commonError } from "src/js/helpers/commonError";
import { setExternalValidation } from "src/js/helpers/form";
import { hideLoader } from "src/js/helpers/loader";
import { isChangeAccount } from "src/js/helpers/urlParams";
import { redirectToUserPanel } from "src/js/helpers/redirect";
import { authToken, IAuthProfile, loadUsers } from "@elsa-zku/lib/helpers/auth";

export type AuthData = {
  user: {
    type_user: USER_TYPE
  }
  token: {
    access_token: string
  }
}

export const checkUser = () => {
  return loadUsers().then(({ current, others }) => {
    if (!isChangeAccount && current) redirectToUserPanel(current.user);
  })
};

const tryAuthUser = (data: AuthData) => {
  if (!data) return commonError();
  const { token, user } = data;
  if (!token || !user) return commonError();
  authToken(token.access_token);
  redirectToUserPanel(user);
};

export const authUser = (formEl: HTMLFormElement, fields: string[], data: AuthData) => {
  const result = tryAuthUser(data);
  if (result) {
    hideLoader(formEl);
    setExternalValidation(formEl, fields, result);
  }
};

export const getUser = (account: IAuthProfile) => account?.user;